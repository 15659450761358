import Icons from "@/assets/Icons";
// import { cn } from "@/utils";

interface Props {
  onClick(): void;
  icon?: keyof typeof Icons;
  className?: string;
}

export const MobileBackButton = ({ onClick, icon = "ArrowLeft", className }: Props) => (
  <></>
  // <div className="mobile-back-button-wrapper">
  //   <button type="button" onClick={onClick} className={cn("mobile-back-button", className)}>
  //     <span className="bordered">{Icons[icon]}</span> Back
  //   </button>
  // </div>
);

export const DesktopBackButton = ({ onClick, icon = "ChevronLeft", className }: Props) => (
  <></>
  // <button
  //   type="button"
  //   className={cn("verification__back-button btn-reset", className)}
  //   onClick={onClick}
  // >
  //   <span>{Icons[icon]}</span>
  //   <span>Back</span>
  // </button>
);
