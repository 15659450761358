import Success from "@/assets/VST-Success.png";
import "~/steps/Step.Success.scss";
import Button from "@/component/fragments/Button";
import { cn, getQueryVariable } from "@/utils";
import { useFlowContext } from "@/context/FlowContext";

interface Props {
  onClick(): void;
  className?: string;
}

const VerificationCompleted = ({ onClick, className }: Props) => {
  const { flowState } = useFlowContext();

  const isStandAlone = getQueryVariable("origin") === "personal";

  const { successImage = Success } = flowState;

  return (
    <div className={cn("vst-success space-y-40", className)}>
      <div className="verification-header">
        <h2 style={{marginTop: "3rem"}} className="verification-title">Your Identity is being verified</h2>
        <p>Please wait while we verify your identity , this might take a few mins</p>
      </div>
      <div className="vst-success__content">
        <figure>
          <img className="success-illustration" src={successImage ?? Success} alt="" />
        </figure>
      </div>
      <div className={cn(isStandAlone && "hide-for-mobile")}>
        <Button onClick={onClick}>Done</Button>
      </div>
    </div>
  );
};

export default VerificationCompleted;
