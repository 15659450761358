import env from "@/constants/env";
import axios from "axios";
import { AxiosError } from "axios";
import { getQueryVariable, sendIframeMessage } from ".";

const isDevTestMode =
  window.location.href.includes("devmode") || process.env.NODE_ENV === "development";

/**
 * https://central.getraventest.com/get_verification_detail?reference=1296725023647088
 */

export const RefAPI = axios.create({
  baseURL: "https://integrations.getravenbank.com/central",
});

const choseTestKey = () => {
  const mode = getQueryVariable("origin");
  const devMode = getQueryVariable("env") === "dev";

  if (mode === "personal")
    return devMode ? env.PERSONAL_PUBLIC_TEST_KEY : env.PERSONAL_PUBLIC_LIVE_KEY;

  if (mode === "bankbox")
    return devMode ? env.BANKBOX_PUBLIC_TEST_KEY : env.BANKBOX_PUBLIC_LIVE_KEY;

  if (mode === "redpay") return devMode ? env.REDPAY_PUBLIC_TEST_KEY : env.REDPAY_PUBLIC_LIVE_KEY;
  if (mode === "business")
    return devMode ? env.BUSINESS_PUBLIC_TEST_KEY : env.BUSINESS_PUBLIC_LIVE_KEY;

  return isDevTestMode ? env.PUBLIC_TEST_KEY : env.PUBLIC_LIVE_KEY;
};

const choseBaseURL = () => {
  const mode = getQueryVariable("origin");
  const devMode = getQueryVariable("env") === "dev";

  if (mode && ["personal", "bankbox", "redpay", "business"].includes(mode))
    return devMode ? env.API_TEST_URL : env.API_LIVE_URL;

  return isDevTestMode ? env.API_TEST_URL : env.API_LIVE_URL;
};

/**
 * @example
 * ```
 * const res = await API.post("/some_url", payload)
 * ```
 */
export const API = axios.create({
  //remove this code
  baseURL: choseBaseURL(),
  headers: {
    "Content-Type": "multipart/form-data",
    //remove this code
    Authorization: `Bearer ${choseTestKey()}`,
  },
});

type APIError = {
  status: boolean;
  message: string;
};

const handleAxiosError = (error: AxiosError<APIError>) => {
  if (error.response) {
    return error.response.data.message;
  }

  if (error.request) {
    return `Bad Request: ${error.request}`;
  }

  return `Error: ${error.message}`;
};

API.interceptors.response.use(
  (resp) => {
    if (resp.data.status === "fail") {
      return Promise.reject(resp.data.message);
    }

    return resp;
  },
  (err) => {
    const error = handleAxiosError(err);

    sendIframeMessage({
      data: { type: "axios", message: error },
      type: "onError",
    });

    return Promise.reject(error);
  }
);

RefAPI.interceptors.response.use(
  (resp) => {
    if (resp.data.status === "fail") {
      return Promise.reject(resp.data.message);
    }

    return resp;
  },
  (err) => {
    const error = handleAxiosError(err);

    sendIframeMessage({
      data: { type: "axios", message: error },
      type: "onError",
    });

    return Promise.reject(error);
  }
);
